<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center no-gutters">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side forgot-section">
            <AuthTop />
            <div class="sign-in-form">
              <form @keyup.enter="verifyCode(0)" @submit.prevent>
                <h4>Code Verification</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else class="tag-line">
                  Please check your email we have sent you a verification code
                  on your registered email.
                </p>

                <div class="form-group">
                  <input
                    id="email"
                    class="form-control"
                    :class="{
                      'is-invalid': validationStatus($v.details.code),
                    }"
                    v-model.trim="$v.details.code.$model"
                    spellcheck="false"
                    autocomplete="off"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Verification Code</span>
                  <div
                    v-if="!$v.details.code.required"
                    class="invalid-tooltip"
                  >
                    Verification code is required
                  </div>
                  <div
                    v-if="!$v.details.code.minLength"
                    class="invalid-tooltip"
                  >
                    Please enter 6 digit code
                  </div>
                  <div
                    v-if="!$v.details.code.maxLength"
                    class="invalid-tooltip"
                  >
                    Please enter 6 digit code
                  </div>
                  <div
                    v-if="!$v.details.code.integer"
                    class="invalid-tooltip"
                  >
                    Code must be a number
                  </div>
                </div>

                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="verifyCode(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Verify Code</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Verify Code</span>
                  </button>
                </div>
              </form>
              <div class="remember-password-line">
              <p>Have remembered your password?</p>
              <router-link to="/login">Back to Login</router-link>
            </div>
            </div>
            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../../components/AuthLeft/Left.vue";
import AuthTop from "../../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../../components/AuthLeft/AuthBottom.vue";

export default {
  name: "Code",
  components: { Left, AuthTop, AuthBottom },
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        code: "",
        email: "",
      },
      isDisabled: true,
    };
  },
  validations: {
    details: {
      code: {
        required,
        integer,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["codeVerify"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    verifyCode: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.email = localStorage.getItem("verifyEmail");
      this.codeVerify(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "NewPassword" });
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
    errorNull: function () {
      this.errorMessage = null;
      if(this.$v.$invalid) {
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false
      }
    },

    email: function () {
      alert();
      this.$router.push({ name: "Email" });
    },
  },
};
</script>

<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side forgot-section">
            <AuthTop />
            <div class="sign-in-form">
              <form @keyup.enter="changePasswordcall(0)" @submit.prevent>
                <h4>Create New Password</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else class="tag-line">
                  Your new password must be different from previously used
                  passwords.
                </p>

                <div class="form-group">
                  <input
                    id="password"
                    class="form-control"
                    :class="{
                      'is-invalid': validationStatus($v.details.password),
                    }"
                    v-model.trim="$v.details.password.$model"
                    spellcheck="false"
                    autocomplete="off"
                    trim
                    :type="type1"
                    required
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">New Password</span>
                  <i
                    v-html="btnText"
                    id="togglePassword"
                    @click="showPassword"
                  ></i>
                  <div
                    v-if="!$v.details.password.required"
                    class="invalid-tooltip"
                  >
                    Please enter password
                  </div>
                  <div
                    v-if="!$v.details.password.minLength"
                    class="invalid-tooltip"
                  >
                    Minimum 8 characters required
                  </div>
                  <div
                    v-if="!$v.details.password.maxLength"
                    class="invalid-tooltip"
                  >
                    Maximum 40 characters allowed.
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="confirm_password"
                    class="form-control"
                    :class="{
                      'is-invalid': validationStatus(
                        $v.details.password_confirmation
                      ),
                    }"
                    v-model.trim="$v.details.password_confirmation.$model"
                    spellcheck="false"
                    autocomplete="off"
                    trim
                    :type="type2"
                    required
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Confirm Password</span>
                  <i
                    v-html="btnText2"
                    id="toggleConfirmPassword"
                    @click="showConfirmPassword"
                  ></i>
                  <div
                    v-if="!$v.details.password_confirmation.required"
                    class="invalid-tooltip"
                  >
                    Enter confirm password
                  </div>
                  <div
                    v-if="!$v.details.password_confirmation.sameAsPassword"
                    class="invalid-tooltip"
                  >
                    Passwords do not match.
                  </div>
                </div>

                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="changePasswordcall(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Reset Password</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Reset Password</span>
                  </button>
                </div>
              </form>
              <div class="remember-password-line">
                <p>Have remembered your password?</p>
                <router-link to="/login">Back to Login</router-link>
              </div>
            </div>
            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../../components/AuthLeft/Left.vue";
import AuthTop from "../../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../../components/AuthLeft/AuthBottom.vue";

export default {
  name: "NewPassword",
  components: { Left, AuthTop, AuthBottom },
  data: function () {
    return {
      isDisabled: true,
      errorMessage: null,
      isLoadingArray: [],
      details: {
        password: "",
        password_confirmation: "",
      },
      type1: "password",
      type2: "password",
      btnText: '<i class="fa fa-eye-slash"></i>',
      btnText2: '<i class="fa fa-eye-slash"></i>',
    };
  },
  validations: {
    details: {
      password: { required, minLength: minLength(8), maxLength: maxLength(40) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["changePassword"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    changePasswordcall: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.code = localStorage.getItem("verifiedCode");
      this.changePassword(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "Login" });
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    showPassword: function () {
      if (this.type1 === "password") {
        this.type1 = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.type1 = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    showConfirmPassword: function () {
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "<i class='fa fa-eye'></i>";
      } else {
        this.type2 = "password";
        this.btnText2 = "<i class='fa fa-eye-slash'></i>";
      }
    },

    errorNull: function () {
      this.errorMessage = null;
      if (
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>

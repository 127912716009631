<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center no-gutters">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side forgot-section">
            <AuthTop />
            <div class="sign-in-form">
              <form
                id="form-wrapper"
                @submit.prevent
                @keyup.enter="sendEmail(0)"
              >
                <h4>Forgot Password ? Don't Worry</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else class="tag-line">
                  We are here to help you to recover your password. Enter the
                  email address you used when you joined and we'll send you six
                  digit code to your Email to <a>reset your password.</a>
                </p>

                <div class="form-group">
                  <input
                    id="email"
                    class="form-control"
                    :class="{
                      'is-invalid': validationStatus($v.details.email),
                    }"
                    v-model.trim="$v.details.email.$model"
                    spellcheck="false"
                    autocomplete="off"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Email</span>
                  <div
                    v-if="!$v.details.email.required"
                    class="invalid-tooltip"
                  >
                    Email is required
                  </div>
                  <div v-if="!$v.details.email.email" class="invalid-tooltip">
                    Provide valid email
                  </div>
                </div>
                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="sendEmail(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send Verification Code</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send Verification Code</span>
                  </button>
                </div>
              </form>
              <div class="remember-password-line">
                <p>Have remembered your password ?</p>
                <router-link to="/login">Back to Login</router-link>
              </div>
            </div>

            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../../components/AuthLeft/Left.vue";
import AuthTop from "../../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../../components/AuthLeft/AuthBottom.vue";

export default {
  name: "Email",
  components: { Left, AuthTop, AuthBottom },
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        email: "",
      },
      isDisabled: true,
    };
  },
  validations: {
    details: {
      email: { required, email },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    document.getElementById("form-wrapper").click();

    // Listen for paste event on the email input field
    const emailInput = document.getElementById("email");
    emailInput.addEventListener("paste", this.handlePaste.bind(this));
  },

  methods: {
    ...mapActions("auth", ["sendResetPasswordRequest"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    // sendEmail: function (index) {
    //   this.errorMessage = null;
    //   this.isDisabled = true;
    //   this.$v.$touch();
    //   if (this.$v.$pendding || this.$v.$error) return;
    //   this.$set(this.isLoadingArray, index, true);
    //   this.sendResetPasswordRequest(this.details).then((response) => {
    //     if (response.data.statusCode == 200) {
    //       this.$router.push({ name: "Code" });
    //     } else {
    //       setTimeout(() => {
    //         this.$set(this.isLoadingArray, index, false);
    //         this.errorMessage = response.data.message;
    //       }, 500);
    //     }
    //   });
    // },
    sendEmail: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.sendResetPasswordRequest(this.details)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$router.push({ name: "Code" });
          } else {
            setTimeout(() => {
              this.$set(this.isLoadingArray, index, false);
              this.errorMessage = response.data.message;
              if (
                response.data.exception === "Swift_TransportException" &&
                response.data.message.includes(
                  "Domain clubteams.org is not allowed to send: Disabled for sending spam"
                )
              ) {
                this.$alert(
                  "Sending failed due to spam prevention measures. Please contact support for assistance."
                );
              }
            }, 500);
          }
        })
        .catch(() => {
          this.$alert(
            "Failed to send the email. Please try again later or contact support."
          );
          this.isDisabled = false;
          this.$set(this.isLoadingArray, index, false);
        });
    },
    errorNull: function () {
      this.errorMessage = null;
      if (this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    handlePaste() {
      setTimeout(() => {
        this.errorNull();
      }, 0);
    },
    email: function () {
      alert();
      this.$router.push({ name: "Email" });
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-wrapper overflow-hidden"},[_c('div',{staticClass:"container-fluid no-pdd"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 d-none d-lg-block no-pdd"},[_c('Left')],1),_c('div',{staticClass:"col-lg-6 no-pdd"},[_c('div',{staticClass:"auth-side forgot-section"},[_c('AuthTop'),_c('div',{staticClass:"sign-in-form"},[_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changePasswordcall(0)},"submit":function($event){$event.preventDefault();}}},[_c('h4',[_vm._v("Create New Password")]),(_vm.errorMessage != null)?_c('p',{staticClass:"tag-line",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_c('p',{staticClass:"tag-line"},[_vm._v(" Your new password must be different from previously used passwords. ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.password.$model),expression:"$v.details.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.validationStatus(_vm.$v.details.password),
                  },attrs:{"id":"password","spellcheck":"false","autocomplete":"off","trim":"","type":_vm.type1,"required":""},domProps:{"value":(_vm.$v.details.password.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("New Password")]),_c('i',{attrs:{"id":"togglePassword"},domProps:{"innerHTML":_vm._s(_vm.btnText)},on:{"click":_vm.showPassword}}),(!_vm.$v.details.password.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Please enter password ")]):_vm._e(),(!_vm.$v.details.password.minLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Minimum 8 characters required ")]):_vm._e(),(!_vm.$v.details.password.maxLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Maximum 40 characters allowed. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.password_confirmation.$model),expression:"$v.details.password_confirmation.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.validationStatus(
                      _vm.$v.details.password_confirmation
                    ),
                  },attrs:{"id":"confirm_password","spellcheck":"false","autocomplete":"off","trim":"","type":_vm.type2,"required":""},domProps:{"value":(_vm.$v.details.password_confirmation.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.password_confirmation, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Confirm Password")]),_c('i',{attrs:{"id":"toggleConfirmPassword"},domProps:{"innerHTML":_vm._s(_vm.btnText2)},on:{"click":_vm.showConfirmPassword}}),(!_vm.$v.details.password_confirmation.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Enter confirm password ")]):_vm._e(),(!_vm.$v.details.password_confirmation.sameAsPassword)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Passwords do not match. ")]):_vm._e()]),_c('div',{staticClass:"event-btns-wrapper"},[(this.isDisabled == false)?_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.changePasswordcall(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Reset Password")])]):(this.isDisabled == true)?_c('button',{staticClass:"btn btn-primary-disabled w-100",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Reset Password")])]):_vm._e()])]),_c('div',{staticClass:"remember-password-line"},[_c('p',[_vm._v("Have remembered your password?")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Back to Login")])],1)]),_c('AuthBottom')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
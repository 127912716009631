<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <div class="main-image">
            <img src="../../../assets/SideImage.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side forgot-section">
            <div class="top-logo">
              <img src="../../../assets/logo.png" alt="logo" />
            </div>
            <form class="sign-in-form forgot-form">
              <h4>Check Your Email</h4>
              <p
                v-if="errorMessage != null"
                class="tag-line"
                style="color: red"
              >
                {{ errorMessage }}
              </p>
              <p v-else class="tag-line">
                We have sent a reset password to your email address.
              </p>

              <div class="remember-password-line">
                <p>Have remembered your password?</p>
                <router-link to="/login">Back to Login</router-link>
              </div>
            </form>
            <div class="account">
              <div class="copyrights">
                <p>2022 &copy; Copyright. Who I Follow</p>
              </div>

              <div class="help">
                <div>
                  <router-link to="/our-privacy-policy"
                    >Privacy Policy</router-link
                  >
                  •
                  <router-link to="/our-terms-and-conditions"
                    >Terms & Conditions</router-link
                  >
                </div>
                <a>Need Help?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Email",
  components: {},
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        email: "",
      },
    };
  },
  validations: {
    details: {
      email: { required, email },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    errorNull: function () {
      this.errorMessage = null;
    },
  },
};
</script>
